import { useMemo } from "react";
import { Popover } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const colorArr = ["#0D324F", "#1C5718", "#A10202"];

export default function OverlappingThumbnail({ overlappingItems }) {
  const content = useMemo(
    () => (
      <div>
        {overlappingItems?.map((item, index) => (
          <p className="my-3" key={index}>
            {item}
          </p>
        ))}
      </div>
    ),
    [overlappingItems],
  );

  return (
    <div className="mx-3">
      <Popover className="flex" placement="bottom" title="Participants" content={content}>
        {overlappingItems.slice(0, 3).map((item, index) => (
          <div
            key={index}
            className={`w-5 h-5 text-white flex justify-center items-center text-xs rounded-full ml-[-0.3rem]`}
            style={{ backgroundColor: colorArr[index] }}
          >
            {index < 2 ? item.charAt(0).toUpperCase() : <PlusOutlined />}
          </div>
        ))}
      </Popover>
    </div>
  );
}
