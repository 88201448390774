import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import CustomTable from "@/components/CustomTable/index.jsx";
import { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import insightServices from "@/services/apiServices/insightServices/index.js";
import {
  CalendarOutlined,
  CloseOutlined,
  DoubleRightOutlined,
  DownOutlined,
  EditFilled,
  InfoCircleFilled,
  InfoCircleOutlined,
  PauseOutlined,
  PlusCircleOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { defaultPriorities } from "@/mocks/index.js";
import useLoading from "@/hooks/useLoading.js";
import eventBus from "@/utils/eventBus.js";
import { EVENT_BUS_EVENTS, PRIORITY_PAIR } from "@/constants/index.js";
import useCalendar from "@/store/useCalendar.js";
import CategoryDropdown from "@/components/CategoryDropdown/index.jsx";
import { getCategoryIdMap, getCategoryName, getDifferenceDate } from "@/utils/index.js";
import useSubcategory from "@/store/useSubcategory.js";
import useChartPills from "@/store/useChartPills.js";
import useFilters from "@/store/useFilters.js";
import OverviewCalendar from "@/pages/Overview/OverviewCalendar.jsx";
import OverlappingThumbnail from "@/components/OverlappingThumbnails";

const { confirm } = Modal;

/* eslint-disable-next-line */
export const iconMap = {
  "very-high": <DoubleRightOutlined className="text-[#EA0000] -rotate-90" />,
  high: <UpOutlined className="text-[#EA0000]" />,
  medium: <PauseOutlined className="text-[#00CF53] rotate-90" />,
  low: <DownOutlined className="text-[#005EEA]" />,
};
const OverviewEventTable = () => {
  const { data: subcategoryData } = useSubcategory();
  const filters = useFilters((store) => store.dashboardFilters);
  const setFilters = useFilters((store) => store.setDashboardFilters);
  const [events, setEvents] = useState([]);
  const setPills = useChartPills((store) => store.setPills);
  const [selectedEvent, setSelectedEvent] = useState({
    start_time: new Date(),
    end_time: new Date(),
  });

  const [selectedField, setSelectedField] = useState({
    rowData: {},
    colName: "",
    isHover: false,
  });

  const calendarIds = useCalendar((store) => store.calendarIds);
  const subCalendarIds = useCalendar((store) => store.subCalendarIds);

  const {
    data: calendarEventData,
    isLoading: isCalendarDataLoading,
    mutate: mutateEventData,
  } = useSWR(
    `fetchEventsData/${filters?.eventTime?.startDate}/${filters?.eventTime?.endDate}/${calendarIds}/${subCalendarIds}`,
    () => insightServices.getEvents(filters?.eventTime, { calendarIds, subCalendarIds }),
  );

  const {
    data: timelineData,
    isLoading: isTimelineDataLoading,
    mutate: mutateTimeline,
  } = useSWR(
    `fetchTimelineData/${filters?.eventTime?.startDate}/${filters.eventTime?.endDate}`,
    () => insightServices.getTimeline(filters?.eventTime),
  );

  const isLoading = isCalendarDataLoading || isTimelineDataLoading;

  const data = useMemo(() => {
    return filters?.eventType === "Calendar"
      ? calendarEventData
      : {
          data: {
            events: timelineData?.data?.entries,
          },
        };
  }, [filters, calendarEventData, timelineData]);

  useEffect(() => {
    if (
      filters.searchText === "" &&
      !filters?.categories?.length &&
      !filters?.priorities?.length
    ) {
      setEvents(data?.data?.events);
      return;
    }
    if (!data?.data?.events) {
      setEvents([]);
      return;
    }
    let filteredEvents = data?.data?.events;
    if (filters.searchText) {
      filteredEvents = filteredEvents.filter((event) =>
        event.summary.toLowerCase().includes(filters.searchText.toLowerCase()),
      );
    }
    if (filters.categories.length) {
      const categoryIds = filters?.categories?.map((item) => item?.id) || [];
      filteredEvents = filteredEvents.filter((event) =>
        categoryIds?.includes(event?.category_id ? Number(event?.category_id) : null),
      );
    }
    if (filters.priorities.length) {
      filteredEvents = filteredEvents.filter((event) =>
        filters.priorities.includes(event.priority),
      );
    }
    if (filters.calendars.length) {
      filteredEvents = filteredEvents.filter((event) =>
        filters.calendars.includes(event.calendar_email),
      );
    }
    setEvents(filteredEvents);
  }, [data, filters, filters.searchText]);

  const { executeAction: saveOption, loading: isSaveOptionLoading } = useLoading(
    async (payload) => {
      try {
        filters?.eventType === "Calendar"
          ? await insightServices.tagEvents(payload)
          : await insightServices.tagTimelineEvents(payload);
        eventBus.emit(EVENT_BUS_EVENTS.REVALIDATE_ALL_EVENTS_CHART, {});
        message.success("Events Updated Successfully.");
      } catch (e) {
        message.error(
          typeof e?.response?.data?.err === typeof String()
            ? e.response.data.err
            : "Something went wrong. Please try again in some time.",
        );
      } finally {
        filters?.eventType === "Calendar"
          ? await mutateEventData()
          : await mutateTimeline();
        setSelectedField({
          rowData: {},
          colName: "",
          isHover: false,
        });
      }
    },
  );

  const onFinish = async (values) => {
    const payload = [
      {
        id: values.id,
        category_id: values?.category_id,
        priority: values.priority,
      },
    ];
    const newEvents = events.map((event) => {
      if (event.id === values.id) {
        return {
          ...event,
          category_id: values?.category_id || event?.category_id,
          priority: values?.priority || event?.priority,
        };
      }
      return event;
    });
    setEvents(newEvents);
    await saveOption(payload);
  };

  const categoryIdMap = useMemo(
    () => getCategoryIdMap(subcategoryData?.data?.categories, {}),
    [subcategoryData],
  );

  const showReminderConfirmation = (payload) => {
    confirm({
      title: "This Event is recurring event, are you sure want to do this for?",
      icon: <InfoCircleFilled />,
      closeIcon: <CloseOutlined />,
      okText: "all recurring events",
      cancelText: "only this event",
      onCancel: () => saveOption([{ ...payload, ignore_nag_type: "THIS_EVENT" }]),
      onOk: () => saveOption([{ ...payload, ignore_nag_type: "ALL_EVENTS" }]),
    });
  };

  const eventsHeaders = useMemo(
    () => [
      {
        title: "Summary",
        dataIndex: "summary",
        width: 350,
        render: (value, record) => (
          <Row
            className="items-center flex"
            onClick={() => {
              setSelectedEvent(record);
              setFilters({ ...filters, isCalendarView: true });
            }}
          >
            <div className="hover:underline flex-1">
              {value}
              {filters?.eventType === "Calendar" && (
                <Tooltip title={record?.calendar_email} className="ml-2">
                  <InfoCircleOutlined />
                </Tooltip>
              )}
            </div>
            {filters?.eventType === "Calendar" && record?.attendees?.length > 0 && (
              <OverlappingThumbnail overlappingItems={record?.attendees} />
            )}
          </Row>
        ),
      },
      {
        title: "Start Time",
        dataIndex: "start_time",
        render: (value) => dayjs(value).format("h:mm A | MMM D, YYYY"),
        sorter: (a, b) => a?.start_time?.localeCompare(b?.start_time),
        defaultSortOrder: "descend",
      },
      {
        title: "Duration",
        dataIndex: "end_time",
        render: (value, record) => {
          const [hh, mm, ss] = getDifferenceDate(record.end_time, record.start_time);
          return `${hh ? Math.abs(hh) + " hr" : ""} ${mm ? Math.abs(mm) + " mins" : ""} ${
            ss ? Math.abs(ss) + " secs" : ""
          } `;
        },
      },
      {
        title: "Category",
        dataIndex: "category_id",
        render: (value, record) => {
          if (
            selectedField.rowData.id === record.id &&
            selectedField.colName === "Category" &&
            !selectedField.isHover
          )
            return (
              <CategoryDropdown
                autoFocus={true}
                isNewCategoryAllowed={true}
                onSelectOption={(option) => {
                  onFinish({
                    id: record.id,
                    category: value,
                    category_id: option?.[option?.length - 1],
                    priority: record.priority,
                  });
                  setSelectedField({
                    rowData: {},
                    colName: "",
                  });
                }}
                onCategoryCreation={(data) => {
                  onFinish({
                    id: record.id,
                    category: value,
                    category_id: data?.data?.id,
                    priority: record.priority,
                  });
                  setSelectedField({
                    rowData: {},
                    colName: "",
                  });
                }}
              />
            );
          if (!value)
            return (
              <Button
                icon={<PlusCircleOutlined />}
                type="dashed"
                onClick={() =>
                  setSelectedField({
                    rowData: record,
                    colName: "Category",
                  })
                }
              >
                Assign Category
              </Button>
            );
          return (
            <Row
              className="items-center"
              onMouseEnter={() =>
                setSelectedField({ rowData: record, colName: "Category", isHover: true })
              }
              onMouseLeave={() =>
                setSelectedField({ rowData: {}, colName: "", isHover: false })
              }
            >
              <div
                className="text-white text-[12px] font-semibold rounded py-[8px] px-[12px] leading-3"
                style={{
                  backgroundColor: categoryIdMap?.[value]?.color
                    ? categoryIdMap?.[value]?.color
                    : isSaveOptionLoading
                    ? ""
                    : categoryIdMap?.[value]?.color || "#4385C2",
                }}
              >
                {categoryIdMap?.[value]?.color ? (
                  <Tooltip
                    title={
                      getCategoryName?.(value, subcategoryData?.data?.categories) || ""
                    }
                  >
                    {categoryIdMap?.[value]?.name}
                  </Tooltip>
                ) : isSaveOptionLoading ? (
                  <Spin />
                ) : (
                  <Tooltip
                    title={
                      getCategoryName?.(value, subcategoryData?.data?.categories) || ""
                    }
                  >
                    {categoryIdMap?.[value]?.name}
                  </Tooltip>
                )}
              </div>
              {selectedField.rowData.id === record.id &&
                selectedField.colName === "Category" &&
                selectedField.isHover && (
                  <EditFilled
                    className="ml-2"
                    onClick={() =>
                      setSelectedField({
                        rowData: record,
                        colName: "Category",
                        isHover: false,
                      })
                    }
                  />
                )}
            </Row>
          );
        },
      },
      {
        title: "Priority",
        dataIndex: "priority",
        width: 150,
        render: (value, record) => {
          if (
            selectedField.rowData.id === record.id &&
            selectedField.colName === "Priority" &&
            !selectedField.isHover
          )
            return (
              <Select
                showSearch
                mode="multiple"
                clearIcon
                className="w-full"
                autoFocus
                defaultOpen
                placeholder="Select Priorities"
                onSelect={(value) => {
                  onFinish({
                    id: record.id,
                    priority: value,
                  });
                  setSelectedField({
                    rowData: {},
                    colName: "",
                  });
                }}
                onBlur={() =>
                  setSelectedField({
                    rowData: {},
                    colName: "",
                  })
                }
              >
                {defaultPriorities?.map((priority) => (
                  <Select.Option value={priority.key} key={`${priority.key} Priority`}>
                    {priority.text}
                  </Select.Option>
                ))}
              </Select>
            );
          if (value === "unassigned")
            return (
              <Button
                icon={<PlusCircleOutlined />}
                type="dashed"
                onClick={() =>
                  setSelectedField({
                    rowData: record,
                    colName: "Priority",
                  })
                }
              >
                Assign Priority
              </Button>
            );
          return (
            <Row
              className="items-center"
              onMouseEnter={() =>
                setSelectedField({ rowData: record, colName: "Priority", isHover: true })
              }
              onMouseLeave={() =>
                setSelectedField({ rowData: {}, colName: "", isHover: false })
              }
            >
              {iconMap[value]} <div className="ml-2">{PRIORITY_PAIR[value] ?? value}</div>
              {selectedField.rowData.id === record.id &&
                selectedField.colName === "Priority" &&
                selectedField.isHover && (
                  <EditFilled
                    className="ml-2"
                    onClick={() =>
                      setSelectedField({
                        rowData: record,
                        colName: "Priority",
                        isHover: false,
                      })
                    }
                  />
                )}
            </Row>
          );
        },
      },
      filters?.eventType === "Calendar"
        ? {
            title: "Reminder",
            dataIndex: "nag_status",
            render: (value, record) => (
              <Switch
                checked={value !== "ignore"}
                onChange={async (value) => {
                  !record?.recurring_event_id?.length
                    ? await saveOption([
                        {
                          id: record?.id,
                          category_id: record?.category_id,
                          priority: record?.priority,
                          ignore_nag: !value,
                          ignore_nag_type: "THIS_EVENT",
                        },
                      ])
                    : showReminderConfirmation({
                        id: record?.id,
                        category_id: record?.category_id,
                        priority: record?.priority,
                        ignore_nag: !value,
                      });
                }}
              />
            ),
          }
        : {},
    ],
    // eslint-disable-next-line
    [
      filters,
      selectedField,
      categoryIdMap,
      isSaveOptionLoading,
      subcategoryData?.data?.categories,
    ],
  );

  useEffect(() => {
    setPills({
      trackedEntry: events?.length,
    });
  }, [events, setPills]);

  useEffect(() => {
    setSelectedEvent({});
  }, [filters?.eventType]);

  return (
    <>
      <Typography.Title level={4} className="m-0 p-0 mb-[24px]">
        Events
      </Typography.Title>
      <Col className="mb-[10px]">
        <Row className="items-center justify-between">
          <Input.Search
            placeholder="Search Event by Summary"
            className="w-[250px]"
            value={filters.searchText}
            onChange={(e) => setFilters({ ...filters, searchText: e.target.value })}
          />
          <Button
            icon={<CalendarOutlined />}
            onClick={() => setFilters({ ...filters, isCalendarView: true })}
          />
        </Row>
      </Col>
      <div className="flex w-[99%]">
        <Col
          className="rounded-xl p-[30px] pb-0 w-full bg-white"
          style={{
            boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
            height: filters?.isCalendarView ? "90vh" : "100%",
          }}
          span={filters?.isCalendarView ? 16 : 24}
        >
          <CustomTable
            tableData={events || []}
            tableHeaders={eventsHeaders}
            isTableLoading={isLoading}
          />
        </Col>
        {filters?.isCalendarView && (
          <Col
            span={8}
            className="rounded-xl mx-2 p-[10px] pb-0 w-full bg-white"
            style={{
              boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
            }}
          >
            <OverviewCalendar
              selectedSlot={{
                start_time: selectedEvent?.start_time,
                end_time: selectedEvent?.end_time,
              }}
              onCancel={() => {
                setFilters({ ...filters, isCalendarView: false });
              }}
              view={filters.eventType}
            />
          </Col>
        )}
      </div>
    </>
  );
};

export default OverviewEventTable;
