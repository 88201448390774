import apiClient from "@/services/apiClient/index.js";

export const urls = {
  getEvents: (days, calendars) => {
    if (calendars?.calendarIds?.length > 0)
      return `/api/events/?start_date=${days.startDate}&end_date=${
        days.endDate
      }&calendar_ids=${calendars?.calendarIds.toString()}&subcalendar_ids=${calendars?.subCalendarIds.toString()}`;
    return `/api/events/?start_date=${days.startDate}&end_date=${days.endDate}`;
  },
  getTaggedEvents: (days, calendars) => {
    if (calendars?.calendarIds?.length > 0)
      return `/api/events-stats/?start_date=${days.startDate}&end_date=${
        days.endDate
      }&calendar_ids=${calendars?.calendarIds.toString()}&subcalendar_ids=${calendars?.subCalendarIds.toString()}`;
    return `/api/events-stats/?start_date=${days.startDate}&end_date=${days.endDate}`;
  },
  getTimeline: (days) =>
    `/api/timeline/?start_date=${days.startDate}&end_date=${days.endDate}`,
  getTimingAppData: (days) => `/api/timing-entries/?date=${days.date}`,
  getInsights: (days, calendars, op_type, num_of_weeks) => {
    if (calendars?.calendarIds?.length > 0)
      return `/api/insights/?start_date=${days.startDate}&end_date=${
        days.endDate
      }&calendar_ids=${calendars?.calendarIds.toString()}&subcalendar_ids=${calendars?.subCalendarIds.toString()}&op_type=${op_type}&num_of_weeks=${num_of_weeks}`;
    return `/api/insights/?start_date=${days.startDate}&end_date=${days.endDate}&op_type=${op_type}&num_of_weeks=${num_of_weeks}`;
  },
  getLinkSlots: (id, days) =>
    `/api/link/${id}/slots/?start_date=${days.startDate}&end_date=${days.endDate}`,
  getLinkGuest: (id) => `/api/link/${id}/guest/`,
  getTimelineInsights: (days, op_type, num_of_weeks) => {
    return `/api/timeline-insights/?start_date=${days.startDate}&end_date=${days.endDate}&op_type=${op_type}&num_of_weeks=${num_of_weeks}`;
  },
  getPreferences: `/api/prefs/`,
  getInsightsTrend: `/api/trend/`,
  getCategoryPriorityData: `/api/user-category-priority/`,
  tagEvents: (eventId) => `/api/tag_event/${eventId || ":eventId"}/`,
  updateTimeline: (timelineId) => `/api/timeline/${timelineId || ":timelineId"}/`,
  updateCalendarEntry: (timelineId) => `/api/event/${timelineId || ":timelineId"}/`,
  createTimeline: `/api/timeline/`,
  createCalendarEntry: `/api/event/`,
  saveReminders: `/api/save-reminders/`,
  savePreferences: `api/save-pref/`,
  getReminders: `/api/reminders/`,
  getCategoryInsights: `/api/category-insights/`,
  saveWorkPreferences: `api/save-work-prefs/`,
  getWorkPref: `api/work-prefs/`,
  getRecEvents: `/api/rec-non-rec-events/`,
  connectTimely: `/api/connect-timing/`,
  getSubCategoryData: "/api/category/",
  createCategory: `/api/category/`,
  updateCategory: (categoryId) => `/api/category/${categoryId || ":categoryId"}/`,
  updateCategoryBudget: (categoryId) =>
    `/api/category/${categoryId || ":categoryId"}/budget/`,
  replaceCategory: (categoryId) => `/api/category/${categoryId}/reassign-delete/`,
  disconnectTimely: `/api/disconnect-timing/`,
  getMockChartData: "https://run.mocky.io/v3/a92b08bf-0960-4a87-aad2-8842d62b83ab",
  getMockEventData: "https://run.mocky.io/v3/4b61754c-a088-4061-9733-fec7046bd032",
  getMockCategoryData: "https://run.mocky.io/v3/f79b4742-297a-46fe-959c-9ea42925decd",
  createTodo: `/api/todo/`,
  getTodoItem: (id) => `/api/todo/${id}/`,
  getTodoSlotsAndLink: (id, duration, zone_id, category_id, priority) =>
    `api/todo/${id}/slots/?duration=${duration}&zone_id=${zone_id}&category_id=${
      category_id || ""
    }&priority=${priority}`,
  getHabitSlots: (id, duration, zone_id, category_id, priority) =>
    `api/habit/${id}/slots/?duration=${duration}&zone_id=${zone_id}&category_id=${
      category_id || ""
    }&priority=${priority}`,
  getGuestTodoSlots: (id) => `api/todo/${id}/guest/`,
  getMockTodoSlotsAndLink: `https://run.mocky.io/v3/4274fb81-266b-4a1f-b096-131658ad5be3`,
  getMockTodos: "https://run.mocky.io/v3/a07ef3c6-a8f8-46b2-b280-f4175b8d139c",
  // getTodos: "/api/todo/",

  getTodos:
    "/api/todo/?fields=id,title,subtitle,guest_scheduling_info,user_scheduling_info,completion_status,scheduling_status,priority,due_date,category_id,duration,last_msg_time,created_at,needs_scheduling,sequence_id",

  updateTodo: (id) => `/api/todo/${id}/`,
  markTodoComplete: (id) => `/api/todo/${id}/completed/`,
  markTodoInComplete: (id) => `/api/todo/${id}/incomplete/`,
  reorderTodo: `/api/todo/reorder/`,
  sendTodoSchedulingLink: (id) => `/api/todo/${id}/send-link/`,
  getZones: "/api/zone/",
  getZone: (id) => `/api/zone/${id}/`,
  scheduleTodo: (id) => `/api/todo/${id}/schedule/`,
  scheduleTodoInBlock: (id) => `/api/todo/${id}/schedule-in-block/`,
  createZone: "/api/zone/",
  updateZone: (id) => `/api/zone/${id}/`,
  markAddCalendarId: "/api/scheduling-subcalendar/",
  markConflictCalendarIds: "/api/subcalendars-for-conflicts/",
  getEventReminders: "/api/nags/",
  updateNag: (id) => `/api/nags/${id}/`,
  scheduleGuestTodo: (id) => `api/todo/${id}/schedule/`,
  scheduleGuestLink: (id) => `api/link/${id}/schedule/`,
  cloneScheduleLink: (id) => `api/link/${id}/clone/`,
  cloneOneOffScheduleLink: (id) => `api/link/${id}/one-off/`,
  deleteScheduleLink: (id) => `api/link/${id}/cancel/`,
  updateGuestLink: (id) => `api/todo/${id}/generate-link/`,
  autoScheduleTodo: (id) => `api/todo/${id}/one-click-schedule/`,
  createScheduleLink: `api/link/`,
  updateScheduleLink: (id) => `api/link/${id}/`,
  createHabit: `api/habit/`,
  updateHabit: (id) => `api/habit/${id}/`,
  getMailTodos: `/api/create-tasks-from-gmail/`,
  getMailStats: `/api/capture-insights/`,
  getMailTodosProgress: (id) => `/api/create-tasks-from-gmail-progress/${id}/`,
  getMailChartProgressData: (id) => `/api/task-progress/${id}/`,
  updateMailTodosProgress: (id) => `/api/update-gmail-from-tasks-progress/${id}/`,
  updateMailTodos: `/api/update-gmail-from-tasks/`,
  createProject: `/api/project/`,
  getProjects: `api/project/`,
  updateProject: (id) => `/api/project/${id}/`,
  syncProject: (id) => `api/project/${id}/sync/`,
  updateProjectGoals: (id) => `/api/project/${id}/update-goals/`,
  syncJira: (id) => `/api/project/${id}/link-goal-with-jira/`,
  getJira: (payload) =>
    `/api/project/${payload.projectId}/jira-issues/?project_key=${payload.projectKey}&issuetype=${payload.issueType}&query=${payload.query}`,
  syncJiraProject: (id) => `api/project/${id}/connect-jira/`,
  getJiraGoals: (id) => `api/project/${id}/linked-goals/`,
  getGoalById: (payload) =>
    `api/project/${payload.projectId}/linked-goals/${payload.goalId}/`,
  getProjectById: (id) => `api/project/${id}/`,
  getJiraProjects: (id) => `api/project/${id}/jira-projects/`,
  getJiraSyncLog: (id) => `api/project/${id}/sync-changelog/`,
  getProjectGoalTodos: (id) => `api/project/${id}/goal-todos/`,
  connectJira: `/api/jira/connect/`,
  refreshTodos: `/api/refresh-todos/`,
  scheduleHabit: (id) => `/api/habit/${id}/schedule/`,
  getTaskBlockers: `/api/task-blocks/`,
  clearTodoSchedule: (id) => `/api/todo/${id}/clear-schedule/`,
  autoScheduleHabit: (id) => `/api/habit/${id}/mark-active/`,
  stopHabit: (id) => `/api/habit/${id}/mark-inactive/`,
  snoozeHabit: (id) => `/api/habit/${id}/snooze/`,
  snoozeMultipleHabit: `/api/habit/pause/`,
  syncHabit: (id) => `/api/habit/${id}/create-and-move/`,
  markHabitBusy: (id) => `/api/habit-event/${id}/mark-busy`,
  getNewDeviceToken: `/api/device/`,
  getIsDeviceAlive: `/api/device/`,
  getEmailActivity: (date) => `/api/activity/?date=${date}`,
};

export default {
  getEvents: (days, calendars) => apiClient.get(urls.getEvents(days, calendars)),
  getTimeline: (days) => apiClient.get(urls.getTimeline(days)),
  getTimingAppData: (days) => apiClient.get(urls.getTimingAppData(days)),
  getRecEvents: () => apiClient.get(urls.getRecEvents),
  getTaggedEvents: (days, calendars) =>
    apiClient.get(urls.getTaggedEvents(days, calendars)),
  getCategoryPriorityData: () => apiClient.get(urls.getCategoryPriorityData),
  getMockChartData: () => apiClient.get(urls.getMockChartData),
  getMockEventData: () => apiClient.get(urls.getMockEventData),
  getMockCategoryData: () => apiClient.get(urls.getMockCategoryData),
  getSubCategoryData: () => apiClient.get(urls.getSubCategoryData),
  getInsights: (days, calendars, op_type, duration) =>
    apiClient.get(urls.getInsights(days, calendars, op_type, duration)),
  getLinkSlots: (id, days, timeZone) =>
    apiClient.get(urls.getLinkSlots(id, days), {
      headers: { "X-User-Timezone": timeZone },
    }),
  getLinkGuest: (id) => apiClient.get(urls.getLinkGuest(id)),
  getTimelineInsights: (days, op_type, duration) =>
    apiClient.get(urls.getTimelineInsights(days, op_type, duration)),
  getPreferences: () => apiClient.get(urls.getPreferences),
  getInsightsTrend: () => apiClient.get(urls.getInsightsTrend),
  tagEvents: async (events) =>
    Promise.all(
      events.map((event) =>
        apiClient.put(urls.tagEvents(event.id), {
          category_id: event?.category_id,
          priority: event?.priority,
          ignore_nag: event?.ignore_nag,
          ignore_nag_type: event?.ignore_nag_type,
        }),
      ),
    ),
  tagTimelineEvents: async (events) =>
    Promise.all(
      events.map((event) =>
        apiClient.put(urls.updateTimeline(event.id), {
          id: event?.id,
          category_id: event?.category_id,
          priority: event?.priority,
        }),
      ),
    ),
  saveReminders: (reminders) => apiClient.post(urls.saveReminders, reminders),
  savePreferences: (payload) => apiClient.post(urls.savePreferences, payload),
  saveWorkPreferences: (payload) => apiClient.post(urls.saveWorkPreferences, payload),
  getReminders: () => apiClient.get(urls.getReminders),
  getCategoryInsights: () => apiClient.get(urls.getCategoryInsights),
  getWeekPref: () => apiClient.get(urls.getWorkPref),
  createTimeline: (payload) => apiClient.post(urls.createTimeline, payload),
  updateTimeline: (payload) => apiClient.put(urls.updateTimeline(payload.id), payload),
  createCalendarEntry: (payload) => apiClient.post(urls.createCalendarEntry, payload),
  updateCalendarEntry: (payload) =>
    apiClient.put(urls.updateCalendarEntry(payload.id), payload),
  removeTimeline: (id) => apiClient.delete(urls.updateTimeline(id)),
  connectTimely: (token) => apiClient.post(urls.connectTimely, { token }),
  createCategory: (payload) => apiClient.post(urls.createCategory, payload),
  updateCategory: (payload) => apiClient.put(urls.updateCategory(payload.id), payload),
  updateCategoryBudget: (payload) =>
    apiClient.put(urls.updateCategoryBudget(payload.id), payload),
  removeCategory: (id) => apiClient.delete(urls.updateCategory(id)),
  replaceCategory: (payload) =>
    apiClient.post(urls.replaceCategory(payload.deleteCategoryId), {
      category_id: payload.replaceCategoryId,
    }),
  disconnectTimely: () => apiClient.delete(urls.disconnectTimely),
  createTodo: (payload) => apiClient.post(urls.createTodo, payload),
  updateTodo: (payload) => apiClient.put(urls.updateTodo(payload.id), payload),
  markTodoComplete: (payload) => apiClient.put(urls.markTodoComplete(payload.id)),
  markTodoInComplete: (payload) =>
    apiClient.put(urls.markTodoInComplete(payload.id), { sequence_id: payload.index }),

  reorderTodo: (payload) => apiClient.put(urls.reorderTodo, payload),

  getTodoSlotsAndLink: (id, duration, zone_id, category_id, priority) =>
    apiClient.get(urls.getTodoSlotsAndLink(id, duration, zone_id, category_id, priority)),
  getHabitSlots: (id, duration, zone_id, category_id, priority, frequency) =>
    apiClient.put(urls.getHabitSlots(id, duration, zone_id, category_id, priority), {
      frequency: frequency,
    }),
  getMockTodoSlotsAndLink: () => apiClient.get(urls.getMockTodoSlotsAndLink),
  getTodoItem: (id) => apiClient.get(urls.getTodoItem(id)),
  getMockTodos: () => apiClient.get(urls.getMockTodos),
  getTodos: () => apiClient.get(urls.getTodos),
  refreshTodos: (payload) => apiClient.post(urls.refreshTodos, payload),
  sendTodoSchedulingLink: (id, payload) =>
    apiClient.put(urls.sendTodoSchedulingLink(id), payload),
  getZones: () => apiClient.get(urls.getZones),
  getZone: (id) => apiClient.get(urls.getZone(id)),
  scheduleTodo: (payload) =>
    apiClient.put(urls.scheduleTodo(payload.id), payload.user_scheduling_info),
  scheduleTodoInBlock: (payload) =>
    apiClient.put(urls.scheduleTodoInBlock(payload.id), { event_id: payload.event_id }),
  createZone: (payload) => apiClient.post(urls.createZone, payload),
  updateZone: (payload) => apiClient.put(urls.updateZone(payload.id), payload),
  deleteZone: (id) => apiClient.delete(urls.updateZone(id)),
  markAddCalendarId: (payload) => apiClient.put(urls.markAddCalendarId, payload),
  markConflictCalendarIds: (payload) =>
    apiClient.put(urls.markConflictCalendarIds, payload),
  getEventReminders: () => apiClient.get(urls.getEventReminders),
  updateNag: (id, payload) => apiClient.put(urls.updateNag(id), payload),
  getGuestTodoSlots: (id, timeZone) =>
    apiClient.get(urls.getGuestTodoSlots(id), {
      headers: { "X-User-Timezone": timeZone },
    }),
  scheduleGuestTodo: (id, payload) => apiClient.put(urls.scheduleGuestTodo(id), payload),
  scheduleGuestLink: (id, payload) => apiClient.put(urls.scheduleGuestLink(id), payload),
  cloneScheduleLink: (id) => apiClient.post(urls.cloneScheduleLink(id)),
  cloneOneOffScheduleLink: (payload) =>
    apiClient.post(urls.cloneOneOffScheduleLink(payload.id), payload),
  updateGuestLink: (id, payload) => apiClient.put(urls.updateGuestLink(id), payload),
  autoScheduleTodo: (id) => apiClient.put(urls.autoScheduleTodo(id)),
  getScheduleLinks: () => apiClient.get(urls.createScheduleLink),
  createScheduleLink: (payload) => apiClient.post(urls.createScheduleLink, payload),
  updateScheduleLink: (payload) =>
    apiClient.put(urls.updateScheduleLink(payload.id), payload),
  deleteScheduleLink: (id) => apiClient.delete(urls.updateScheduleLink(id)),
  getHabits: () => apiClient.get(urls.createHabit),
  createHabit: (payload) => apiClient.post(urls.createHabit, payload),
  updateHabit: (payload) => apiClient.put(urls.updateHabit(payload.id), payload),
  getMailTodos: () => apiClient.post(urls.getMailTodos),
  getMailStats: () => apiClient.post(urls.getMailStats),
  getMailTodosProgress: (id) => apiClient.get(urls.getMailTodosProgress(id)),
  updateMailTodosProgress: (id) => apiClient.get(urls.updateMailTodosProgress(id)),
  updateMailTodos: () => apiClient.post(urls.updateMailTodos),
  createProject: (payload) => apiClient.post(urls.createProject, payload),
  getProjects: () => apiClient.get(urls.getProjects),
  getProjectById: (id) => apiClient.get(urls.getProjectById(id)),
  updateProject: (payload) => apiClient.put(urls.updateProject(payload.id), payload),
  syncProject: (id) => apiClient.put(urls.syncProject(id)),
  updateProjectGoals: (payload) =>
    apiClient.put(urls.updateProjectGoals(payload.id), { goals: payload.goals }),
  syncJira: (payload) => apiClient.put(urls.syncJira(payload.projectId), payload),
  getJira: (payload) => apiClient.get(urls.getJira(payload)),
  getJiraGoals: (id) => apiClient.get(urls.getJiraGoals(id)),
  syncJiraProject: (payload) => apiClient.put(urls.syncJiraProject(payload.id), payload),
  getGoalById: (payload) => apiClient.get(urls.getGoalById(payload)),
  getJiraProjects: (id) => apiClient.get(urls.getJiraProjects(id)),
  getJiraSyncLog: (id) => apiClient.get(urls.getJiraSyncLog(id)),
  deleteProject: (id) => apiClient.delete(urls.getProjectById(id)),
  getProjectGoalTodos: (id) => apiClient.get(urls.getProjectGoalTodos(id)),
  connectJira: (payload) => apiClient.post(urls.connectJira, payload),
  scheduleHabit: (id, payload) => apiClient.put(urls.scheduleHabit(id), payload),
  getTaskBlockers: () => apiClient.get(urls.getTaskBlockers),
  clearTodoSchedule: (id) => apiClient.put(urls.clearTodoSchedule(id)),
  autoScheduleHabit: (id) => apiClient.put(urls.autoScheduleHabit(id)),
  stopHabit: (id) => apiClient.put(urls.stopHabit(id)),
  snoozeHabit: (payload) => apiClient.put(urls.snoozeHabit(payload.id), payload),
  snoozeMultipleHabit: (payload) => apiClient.put(urls.snoozeMultipleHabit, payload),
  syncHabit: (id) => apiClient.put(urls.syncHabit(id)),
  markHabitBusy: (id) => apiClient.put(urls.markHabitBusy(id)),
  getMailChartProgressData: (id) => apiClient.get(urls.getMailChartProgressData(id)),
  getNewDeviceToken: () => apiClient.post(urls.getNewDeviceToken, null),
  getIsDeviceAlive: () => apiClient.get(urls.getIsDeviceAlive),
  getEmailActivity: (date) => apiClient.get(urls.getEmailActivity(date)),
};
