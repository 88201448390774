import { create } from "zustand";
import { persist } from "zustand/middleware";
import dayjs from "dayjs";

const useFilters = create(
  persist(
    (set) => ({
      filters: {
        priorities: [],
        calendars: [],
        categories: [],
        eventTime: {
          startDate: dayjs().day(0).format("YYYY-MM-DD"),
          endDate: dayjs().day(6).format("YYYY-MM-DD"),
        },
        // eventType: "Calendar",
        weekDuration: 1,
        todoType: "tasks",
        block: {},
        blocks: [],
      },
      dashboardFilters: {
        priorities: [],
        calendars: [],
        categories: [],
        eventTime: {
          startDate: dayjs().day(0).format("YYYY-MM-DD"),
          endDate: dayjs().day(6).format("YYYY-MM-DD"),
        },
        eventType: "Calendar",
        weekDuration: 1,
        todoType: "tasks",
        chartSelectedCategories: [],
        chartDefaultCategories: [],
        searchText: "",
        isCalendarView: false,
        chartType: "week_review",
        chartRespondType: "mail",
        chartGraphBars: "checkpoint",
        taskChartGraphFilter: "overdue_tasks",
        chartPriorityTime: "auto-start-of-day",
        chartPriorities: ["very-high", "high", "medium", "low", "unassigned"],
        chartMailMetrics: "threads",
        chartTaskMetrics: "overdue_stats",
        chartAggregateFunction: "mean",
        chartPercentileValue: 50,
        chartFavouritesFilters: {},
        selectedChartFavourite: "",
        newChartRespondType: "mail",
      },
      taskFilters: {
        searchText: "",
        priorities: [],
        zones: [],
        schedule_status: [],
        completion_status: ["incomplete"],
        dueDates: [],
        dueRange: [],
        view: "table",
        group_view: "due_date",
        group_by: "group_priority",
        columns: ["Duration", "Priority", "Due Date", "Last Mail Date", "Link", "Action"],
        defaultColumns: [
          "Duration",
          "Priority",
          "Scheduled time",
          "Last Mail Date",
          "Due Date",
          "Category",
          "Link",
          "Zone",
          "Scheduling",
          "Completion",
          "Action",
        ],
      },
      habitFilters: {
        text: "",
        zones: [],
        duration: null,
        durationSlots: null,
        priority: [],
        frequency: [],
        eventTime: {
          startDate: dayjs().day(0).format("YYYY-MM-DD"),
          endDate: dayjs().day(6).format("YYYY-MM-DD"),
        },
        selectedEvents: [],
      },
      goalFilters: {
        title: "",
        status: [],
        issueType: [],
        assignee: [],
        goalViewType: "table",
      },
      scheduleLinkFilters: {
        text: "",
        zones: [],
        duration: null,
        durationSlots: null,
        priorities: [],
      },
      setFilters: (newFilters) => {
        set({
          filters: { ...newFilters },
        });
      },
      setTaskFilters: (newFilters) => {
        set({
          taskFilters: { ...newFilters },
        });
      },
      setHabitFilters: (newFilters) => {
        set({
          habitFilters: { ...newFilters },
        });
      },
      setScheduleLinkFilters: (newFilters) => {
        set({
          scheduleLinkFilters: { ...newFilters },
        });
      },
      setDashboardFilters: (newFilters) => {
        set({
          dashboardFilters: { ...newFilters },
        });
      },
      setGoalFilters: (newFilters) => {
        set({
          goalFilters: { ...newFilters },
        });
      },
    }),
    {
      name: "useFilters", // Key to store in localStorage
      partialize: (state) => ({ dashboardFilters: state.dashboardFilters }),
    },
  ),
);

export default useFilters;
