import { useEffect, useState } from "react";
import insightServices from "@/services/apiServices/insightServices/index.js";
import { Row, Spin } from "antd";

const Devices = () => {
  const [deviceTokenList, setDeviceTokenList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function myFn() {
      try {
        setIsLoading(true);
        const resp = await insightServices.getIsDeviceAlive();

        const deviceList = resp?.data?.devices;
        if (deviceList && deviceList.length > 0) {
          setDeviceTokenList([deviceList[0]]);
        }
      } catch (e) {
        console.error("Error: ", e);
      } finally {
        setIsLoading(false);
      }
    }
    myFn();
  }, []);

  const onAddDeviceClick = async function () {
    try {
      const resp = await insightServices.getNewDeviceToken();

      setDeviceTokenList((prevList) => [...prevList, resp?.data]);
    } catch (err) {
      console.error(err);
    }
  };

  const getLastActive = (timeStr) => {
    if (!timeStr) return "N.A.";

    return new Date(timeStr).toLocaleString();
  };

  return (
    <div>
      {isLoading ? (
        <Row className="items-center justify-center h-[514px] w-[400px]">
          <Spin />
        </Row>
      ) : (
        <>
          <h1>Devices</h1>
          <button
            disabled={deviceTokenList.length > 0}
            className="mb-3"
            onClick={onAddDeviceClick}
          >
            Add new device
          </button>

          {deviceTokenList.map((item) => (
            <div key={item.id}>
              <div>
                Token: <span style={{ fontWeight: "bold" }}> {item.token}</span>
              </div>
              <div>Last active: {getLastActive(item.alive_at)}</div>
              <hr />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Devices;
