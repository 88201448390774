import Header from "@/components/Header/index.jsx";
import { Col, Divider, Menu, Row, Typography } from "antd";
import FiniteTimeLogo from "@/assets/new_finitetime_logo_box.svg";
import { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const menuItems = [
  {
    label: "Calendar",
    path: "calendar",
  },
  {
    label: "Category",
    path: "category",
  },
  {
    label: "Devices",
    path: "devices",
  },
  {
    label: "Reminders",
    path: "reminders",
  },
  {
    label: "Weekly hours",
    path: "weekly-hours",
  },
  {
    label: "Zones",
    path: "zones",
  },
  {
    label: "Connected apps",
    path: "connected-apps",
  },
];

const menuItemsHeaders = {
  calendar: {
    title: "Calendar",
    subTitle:
      "Manage your calendar. Check for conflicts to prevent double bookings, and Set the calendar you would like to add new events to as they are scheduled",
  },
  category: {
    title: "Categories",
    subTitle:
      "Manage your content hierarchy with the categories. Create, delete, and modify categories to tailor your organisational structure.",
  },
  reminders: {
    title: "Reminders",
    subTitle:
      "Stay on top of your priorities with the Reminders subsection. Customise and manage timely alerts to ensure you never miss an important task or deadline.",
  },
  "weekly-hours": {
    title: "Weekly hours",
    subTitle:
      "Define your weekly work schedule to empower your calendar for smarter and more efficient planning.",
  },
  zones: {
    title: "Work zones",
    subTitle:
      "Create designated zones in your calendar for different types of tasks, allowing you to seamlessly organize and prioritize your work.",
  },
  "connected-apps": {
    title: "Calendar",
    subTitle:
      "Effortlessly link and manage external applications to enhance collaboration and streamline your workflow.",
  },
};

const ProfileSetting = () => {
  const navigate = useNavigate();
  const [pathname, setPathname] = useState(location?.pathname);

  useEffect(() => {
    const currentLocation = location?.pathname?.split("/");
    setPathname(currentLocation?.[currentLocation.length - 1]);
    // eslint-disable-next-line
  }, [location?.pathname]);

  useEffect(() => {
    if (pathname === "profile") navigate("calendar");
  }, [pathname, navigate]);

  const currentHeader = useMemo(() => {
    return menuItemsHeaders?.[pathname];
  }, [pathname]);

  return (
    <Row key={pathname}>
      <Col
        style={{
          overflow: "auto",
          height: "95vh",
          position: "fixed",
          width: "260px",
        }}
      >
        <div className="mt-[28px] mb-[20px]">
          <FiniteTimeLogo className="w-[68%] h-[50%]" />
        </div>
        <ArrowLeftOutlined
          className="text-[20px] mx-[20px]"
          onClick={() => navigate("/overview")}
        />
        <Typography.Text className="text-[20px] font-semibold">Settings</Typography.Text>
        <Menu
          mode={"vertical"}
          style={{
            borderRight: "unset",
            marginTop: "20px",
          }}
          defaultSelectedKeys={[`${pathname}`]}
        >
          {menuItems.map((menuItem) => (
            <Menu.Item
              key={menuItem.path}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "20px",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                borderRadius: " 0px 50px 50px 0px",
                height: "60px",
              }}
            >
              <Row key={menuItem.label} className="w-full">
                <Typography.Text className="text-[16px] leading-[24px]">
                  <Link to={menuItem.path}>{menuItem.label}</Link>
                </Typography.Text>
              </Row>
            </Menu.Item>
          ))}
        </Menu>
      </Col>
      <Col
        style={{
          overflow: "auto",
          marginLeft: "340px",
          marginRight: "2%",
          position: "relative",
          marginBottom: "24px",
          height: "100%",
          width: "100%",
        }}
      >
        <div>
          <Header />
        </div>
        <Row className="flex-col">
          <Typography.Text className="text-[16px] font-semibold leading-[24px]">
            {currentHeader?.title}
          </Typography.Text>
          <Typography.Text className="text-[14px] leading-[24px]">
            {currentHeader?.subTitle}
          </Typography.Text>
        </Row>
        <Divider />
        <Outlet />
      </Col>
    </Row>
  );
};

export default ProfileSetting;
