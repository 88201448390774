import { create } from "zustand";

const useExtensionAuthStore = create((set) => ({
  isRedirectToExtesionAuthPage: false,

  setIsRedirectToExtesionAuthPage: (value) =>
    set({ isRedirectToExtesionAuthPage: value }),
}));

export default useExtensionAuthStore;
