import { useCallback, useMemo, useState } from "react";
import { week, weekS } from "@/constants/index.js";
import { Calendar, dayjsLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import dayjs from "dayjs";
import "./Zones.css";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Empty,
  Input,
  message,
  Modal,
  Row,
  Select,
  TimePicker,
  Typography,
} from "antd";
import {
  CalendarOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import useLoading from "@/hooks/useLoading.js";
import Stroke from "@/assets/stroke.svg";
import ColorDropDown from "@/components/ColorDropDown/index.jsx";
import insightServices from "@/services/apiServices/insightServices/index.js";
import useLocalStorage from "@/hooks/useLocalStorage.js";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useUser from "@/store/useUser.js";
import useZones from "@/store/useZones.js";
import userService from "@/services/apiServices/userService/index.js";

const localizer = dayjsLocalizer(dayjs);
const Zones = ({ isOnlyModal = false }) => {
  const { data: userData, mutate: mutateUserData } = useUser();
  const { data: zoneData, mutate: mutateZoneData } = useZones();
  const [modalType, setModalType] = useState(isOnlyModal || false);
  const [currentHoverZone, setCurrentHoverZone] = useState();
  const [currentInvisibleZones, setCurrentInvisibleZones] = useLocalStorage(
    "invisibleZones",
    [],
  );

  const [selectedZoneEvent, setSelectedZoneEvent] = useState({
    id: null,
    title: null,
    color: null,
    slots: {},
    days: [],
    startTime: null,
    endTime: null,
    day: null,
  });

  const { defaultDate, views } = useMemo(
    () => ({
      defaultDate: new Date(),
      views: ["day"],
    }),
    [],
  );

  const zoneResourceMap = useMemo(() => {
    return week.map((weekItem, index) => ({
      resourceId: index,
      resourceTitle: weekItem,
    }));
  }, []);

  const zoneEvents = useMemo(() => {
    let currentZones = zoneData?.data?.zones;
    const events = [];
    if (currentHoverZone) {
      currentZones = [currentHoverZone];
    } else if (currentInvisibleZones?.length > 0) {
      currentZones = currentZones?.filter(
        (zone) =>
          !currentInvisibleZones?.find((currentZone) => currentZone.id === zone.id),
      );
    }
    currentZones?.forEach((zone) => {
      Object.entries(zone.slots)?.map(([weekDayName, weekDaySlots]) => {
        weekDaySlots.map((slotValue) => {
          let [hh, mm] = slotValue.start_time.split(":");
          const dayJsStartTime = dayjs()
            .set("hours", Number(hh))
            .set("minutes", Number(mm));
          [hh, mm] = slotValue.end_time.split(":");
          const dayJsEndtime = dayjs()
            .set("hours", Number(hh))
            .set("minutes", Number(mm));
          events.push({
            ...zone,
            start: new Date(dayJsStartTime),
            end: new Date(dayJsEndtime),
            resourceId: [weekS.indexOf(String(weekDayName))],
          });
        });
      });
    });
    return events;
  }, [zoneData, currentHoverZone, currentInvisibleZones]);

  const handleSelectSlot = useCallback((props) => {
    setModalType(true);
    setSelectedZoneEvent({
      ...props,
      startTime: dayjs(props?.start),
      endTime: dayjs(props?.end),
    });
  }, []);

  const ZoneToolbar = () => <></>;
  const eventStyleGetter = (event) => {
    const backgroundColor = event?.color ?? "#4385C2";
    const style = {
      backgroundColor: backgroundColor,
      borderRadius: "2px",
      color: "white",
      padding: "10px",
      border: "1px solid #FFF",
      fontSize: "14px",
      lineHeight: "20px",
    };
    return {
      style: style,
    };
  };

  const { executeAction: handleCreateZoneEntry, loading: isCreateZoneEntryLoading } =
    useLoading(async () => {
      const payload = {
        ...(selectedZoneEvent?.id && { id: selectedZoneEvent?.id }),
        ...(selectedZoneEvent?.color && { color: selectedZoneEvent?.color }),
        title: selectedZoneEvent?.title,
        slots: { Fri: [], Mon: [], Sat: [], Sun: [], Thu: [], Tue: [], Wed: [] },
      };

      if (!payload?.title || payload?.title?.length === 0) {
        message.error("Title required");
        return;
      }

      const day = selectedZoneEvent?.day;

      if (!day || day?.length === 0) {
        message.error("Day required");
        return;
      }

      if (!selectedZoneEvent?.startTime || !selectedZoneEvent?.endTime) {
        message.error("Time range required");
        return;
      }

      const sTime =
        String(dayjs(selectedZoneEvent?.startTime).get("hours"))?.padStart(2, "0") +
        ":" +
        String(dayjs(selectedZoneEvent?.startTime).get("minutes"))?.padStart(2, "0");

      const eTime =
        String(dayjs(selectedZoneEvent?.endTime).get("hours"))?.padStart(2, "0") +
        ":" +
        String(dayjs(selectedZoneEvent?.endTime).get("minutes"))?.padStart(2, "0");

      day?.forEach((currentDay) => {
        payload.slots[currentDay].push({
          start_time: sTime,
          end_time: eTime,
        });
      });

      try {
        // create zone api call
        const newZoneData = payload?.id
          ? await insightServices.updateZone(payload)
          : await insightServices.createZone(payload);

        if (!payload?.id) {
          const userZoneIds = userData?.data?.settings?.zone_ids
            ? userData?.data?.settings?.zone_ids
            : [];
          await userService.updateSettings({
            zone_ids: [newZoneData?.data?.id, ...userZoneIds],
          });
        }

        await mutateZoneData();
        await mutateUserData?.();
        message.success(
          payload?.id ? "Zone updated successfully" : "Zone created Successfully",
        );
      } catch (e) {
        message.error(
          typeof e?.response?.data?.msg === typeof String()
            ? e.response.data.msg
            : "Something went wrong. Please try again in some time.",
        );
      } finally {
        setModalType(false);
        setSelectedZoneEvent({});
      }
    });

  const { executeAction: handleDeleteZoneEntry, loading: isDeleteZoneEntryLoading } =
    useLoading(async () => {
      try {
        await insightServices.deleteZone(selectedZoneEvent?.id);

        let zoneIds = userData?.data?.settings?.zone_ids;
        zoneIds = zoneIds?.filter((zone) => zone?.id === selectedZoneEvent?.id);
        await userService.updateSettings({
          zone_ids: zoneIds,
        });

        await mutateZoneData();
        await mutateUserData?.();

        setCurrentInvisibleZones(
          currentInvisibleZones.filter((item) => item.id !== selectedZoneEvent.id),
        );

        message.success("Zone deleted successfully");
      } catch (e) {
        message.error(
          typeof e?.response?.data?.msg === typeof String()
            ? e.response.data.msg
            : "Something went wrong. Please try again in some time.",
        );
      } finally {
        setModalType(false);
        setSelectedZoneEvent({});
      }
    });

  const firstAvailableSlotTime = useMemo(() => {
    const hoveredSlots = currentHoverZone?.slots;
    let scrollTime = new Date(new Date().setHours(8, 1));
    let flag = false;
    weekS?.forEach((day) => {
      if (hoveredSlots?.[day]?.length > 0 && !flag) {
        const slotValue = hoveredSlots?.[day]?.[0];
        flag = true;
        let [hh, mm] = slotValue?.start_time?.split(":");
        scrollTime = new Date(
          new Date().setHours(Number(hh) >= 1 ? Number(hh) - 1 : Number(hh), Number(mm)),
        );
      }
    });
    return scrollTime;
  }, [currentHoverZone]);

  const editVisibleZone = (zone) => {
    let filterZones = currentInvisibleZones || [];
    const isZoneAvailable = filterZones.some((currentZone) => currentZone.id === zone.id);
    if (isZoneAvailable) {
      filterZones = filterZones.filter((currentZone) => currentZone.id !== zone.id);
    } else {
      filterZones.push(zone);
    }
    setCurrentInvisibleZones([...filterZones]);
  };

  const isZoneVisible = (zone) => {
    return !currentInvisibleZones.some((currentZone) => currentZone?.id === zone?.id);
  };

  const getSelectedZoneDays = (zone) => {
    const currentSlots = zone.slots;
    const arr = [];
    for (const day in currentSlots) {
      if (currentSlots[day].length > 0) {
        arr.push(day);
      }
    }
    return arr;
  };

  const getSelectedZoneTime = (zone) => {
    const currentSlots = zone.slots;
    let obj = {};
    for (const day in currentSlots) {
      if (currentSlots[day]?.length > 0) {
        const slotValue = currentSlots[day]?.[0];

        let [hh, mm] = slotValue.start_time.split(":");
        const dayJsStartTime = dayjs()
          .set("hours", Number(hh))
          .set("minutes", Number(mm));
        [hh, mm] = slotValue.end_time.split(":");
        const dayJsEndtime = dayjs().set("hours", Number(hh)).set("minutes", Number(mm));

        obj = {
          startTime: dayJsStartTime,
          endTime: dayJsEndtime,
        };
      }
    }
    return obj;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source?.index === destination?.index) return;

    const referenceZones = userData?.data?.settings?.zone_ids ?? [];
    const [removedItem] = referenceZones?.splice(source?.index, 1);
    referenceZones?.splice(destination?.index, 0, removedItem);

    const currentZones = zoneData?.data?.zones || [];

    currentZones?.sort((a, b) => {
      return referenceZones?.indexOf(a?.id) - referenceZones?.indexOf(b?.id);
    });

    try {
      await userService.updateSettings({
        zone_ids: currentZones?.map((zone) => zone?.id) || [],
      });
      await mutateUserData?.();
    } catch (e) {
      message.error("Something went wrong. Please try again in some time.");
    }
  };

  const sortedZones = useMemo(() => {
    if (!zoneData) return [];
    if (zoneData?.data?.zones?.length > 0 && !userData?.data?.settings?.zone_ids?.length)
      return zoneData?.data?.zones;

    const currentZones = zoneData?.data?.zones || [];
    const referenceZones = userData?.data?.settings?.zone_ids || [];
    currentZones?.sort((a, b) => {
      return referenceZones?.indexOf(a?.id) - referenceZones?.indexOf(b?.id);
    });

    return currentZones;
  }, [userData, zoneData]);

  return (
    <>
      {!isOnlyModal && (
        <Row>
          <Col span={5}>
            <Button
              type="primary"
              className="bg-primaryDark mb-[10px]"
              onClick={() => setModalType(true)}
            >
              Add Zone
            </Button>
            <div className="my-[10px]">
              <Typography.Text className="text-[14px] leading-[22px] font-semibold my-[10px]">
                My Zones
              </Typography.Text>
            </div>
            <Row className="flex-wrap w-full">
              <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                <Droppable droppableId={"ZoneDrop"} key={"ZoneDrop"}>
                  {(provided) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="w-full h-[80vh] overflow-auto"
                      >
                        {sortedZones?.length > 0 ? (
                          sortedZones?.map((zone, index) => {
                            return (
                              <Draggable
                                key={String(zone.id)}
                                draggableId={String(zone.id)}
                                index={index}
                              >
                                {(provided) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Row
                                        key={zone?.id}
                                        className="flex justify-between w-full items-center m-[4px] p-[4px]"
                                      >
                                        <div>
                                          <Avatar
                                            className="mr-2 w-[12px] h-[12px]"
                                            style={{ background: zone?.color }}
                                          />
                                          <Typography.Text className="mr-2">
                                            {zone?.title}
                                          </Typography.Text>
                                        </div>
                                        <div>
                                          <Button
                                            type="text"
                                            icon={
                                              isZoneVisible(zone) ? (
                                                <EyeOutlined />
                                              ) : (
                                                <EyeInvisibleOutlined />
                                              )
                                            }
                                            onMouseEnter={() => setCurrentHoverZone(zone)}
                                            onMouseLeave={() => setCurrentHoverZone()}
                                            className="m-0 p-0"
                                            onClick={() => editVisibleZone(zone)}
                                          />
                                          <Button
                                            type="text"
                                            icon={<EditOutlined />}
                                            onClick={() => {
                                              setModalType(true);
                                              setSelectedZoneEvent({
                                                ...zone,
                                                day: getSelectedZoneDays(zone),
                                                ...getSelectedZoneTime(zone),
                                              });
                                            }}
                                          />
                                        </div>
                                      </Row>
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })
                        ) : (
                          <Empty />
                        )}
                      </div>
                    );
                  }}
                </Droppable>
              </DragDropContext>
            </Row>
          </Col>
          <div>
            <Divider type="vertical h-full w-[1px] mx-[30px]" />
          </div>
          <Col span={17} className="h-[90vh] overflow-auto">
            <Calendar
              defaultDate={defaultDate}
              defaultView={Views.DAY}
              events={zoneEvents}
              localizer={localizer}
              resourceIdAccessor="resourceId"
              resources={zoneResourceMap}
              dayLayoutAlgorithm="no-overlap"
              onSelectSlot={handleSelectSlot}
              resourceTitleAccessor="resourceTitle"
              step={30}
              selectable
              views={views}
              eventPropGetter={eventStyleGetter}
              components={{
                toolbar: ZoneToolbar,
              }}
              scrollToTime={firstAvailableSlotTime}
              key={firstAvailableSlotTime}
            />
          </Col>
        </Row>
      )}
      {!isOnlyModal && <Divider />}
      <Modal
        getContainer={document.getElementById("root")}
        destroyOnClose
        width={450}
        open={modalType}
        onCancel={() => {
          setModalType(false);
          setSelectedZoneEvent({});
        }}
        title={
          <Input
            placeholder="Add title"
            bordered={false}
            size="large"
            autoFocus
            defaultValue={selectedZoneEvent?.title}
            value={selectedZoneEvent?.title}
            className="text-[24px] m-0 p-0"
            onChange={(e) =>
              setSelectedZoneEvent({
                ...selectedZoneEvent,
                title: e.target.value,
              })
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleCreateZoneEntry();
              }
            }}
          />
        }
        footer={[
          selectedZoneEvent?.id && (
            <Button
              key="zone_delete"
              loading={isDeleteZoneEntryLoading}
              onClick={handleDeleteZoneEntry}
            >
              Delete
            </Button>
          ),
          <Button
            key="submit"
            type="primary"
            className="bg-primary"
            loading={isCreateZoneEntryLoading}
            onClick={handleCreateZoneEntry}
          >
            {selectedZoneEvent?.id ? "Update" : "Create"}
          </Button>,
        ]}
      >
        <>
          <Row className="mb-[20px] items-center justify-between">
            <Row className="items-center">
              <Stroke className="mr-[16px]" />
              <Typography.Text className="text-[14px] font-normal leading-[24px]">
                Color
              </Typography.Text>
            </Row>
            <ColorDropDown
              defaultColor={selectedZoneEvent?.color}
              onChange={(color) =>
                setSelectedZoneEvent({ ...selectedZoneEvent, color: color })
              }
              width={320}
            />
          </Row>
          <Row className="my-[20px] items-center justify-between">
            <Row className="items-center">
              <CalendarOutlined className="mr-[16px]" />
              <Typography.Text className="text-[14px] font-normal leading-[24px]">
                <span className="text-red-700">*</span>Day
              </Typography.Text>
            </Row>
            <Select
              showSearch
              mode="multiple"
              clearIcon
              className="w-[314px]"
              placeholder="Select Day"
              defaultValue={selectedZoneEvent.day}
              value={selectedZoneEvent.day}
              onChange={(value) => {
                setSelectedZoneEvent({
                  ...selectedZoneEvent,
                  day: value,
                });
              }}
            >
              {weekS?.map((weekDay) => (
                <Select.Option key={weekDay} value={weekDay}>
                  {weekDay}
                </Select.Option>
              ))}
            </Select>
          </Row>
          <Row className="mb-[20px] justify-between">
            <div className="mt-[4px]">
              <CalendarOutlined className="mr-[16px]" />
              <Typography.Text className="text-[14px] font-normal leading-[24px]">
                <span className="text-red-700">*</span>Time
              </Typography.Text>
            </div>
            <div className="flex w-[314px]">
              <div className="flex-col w-full">
                <Row className="items-center justify-between">
                  <Typography.Text className="text-[14px] text-[#606369] font-normal leading-[24px]">
                    Start Time
                  </Typography.Text>
                  <div className="w-[70%]">
                    <TimePicker
                      format={"HH:mm"}
                      needConfirm={false}
                      showNow={false}
                      onChange={(value) =>
                        setSelectedZoneEvent({
                          ...selectedZoneEvent,
                          startTime: value,
                        })
                      }
                      value={selectedZoneEvent?.startTime}
                    />
                  </div>
                </Row>
                <Row className="items-center justify-between mt-[10px]">
                  <Typography.Text className="text-[14px] text-[#606369] font-normal leading-[24px]">
                    End Time
                  </Typography.Text>
                  <div className="w-[70%]">
                    <TimePicker
                      format={"HH:mm"}
                      needConfirm={false}
                      showNow={false}
                      onChange={(value) =>
                        setSelectedZoneEvent({
                          ...selectedZoneEvent,
                          endTime: value,
                        })
                      }
                      value={selectedZoneEvent?.endTime}
                    />
                  </div>
                </Row>
              </div>
            </div>
          </Row>
        </>
      </Modal>
    </>
  );
};

export default Zones;
