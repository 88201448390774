import { createBrowserRouter } from "react-router-dom";
import App from "@/App.jsx";
import Error from "@/components/Error/index.jsx";
import Login from "@/pages/Login/index.jsx";
import { lazy } from "react";
import ProfileSetting from "@/pages/ProfileSetting/index.jsx";
import CalendarSettings from "@/components/CalendarSettings/index.jsx";
import CategorySettings from "@/components/CategorySettings/index.jsx";
import Devices from "@/components/Devices/index.jsx";
import ReminderModal from "@/components/ReminderModal/index.jsx";
import WeekPreferenceModal from "@/components/WeekPreferenceModal/index.jsx";
import Zones from "@/components/Zones/index.jsx";
import ConnectedApps from "@/components/ConnectedApps/index.jsx";

// eslint-disable-next-line
const Overview = lazy(() => import("@/pages/Overview/index.jsx"));
// eslint-disable-next-line
const FiniteTimeline = lazy(() => import("@/pages/FiniteTimeline/index.jsx"));
// eslint-disable-next-line
const TodoList = lazy(() => import("@/pages/Todo/index.jsx"));
// eslint-disable-next-line
const GuestSlots = lazy(() => import("@/pages/GuestSlots/index.jsx"));
// eslint-disable-next-line
const ScheduleLinks = lazy(() => import("@/pages/ScheduleLinks/index.jsx"));
// eslint-disable-next-line
const TimeBudget = lazy(() => import("@/pages/TimeBudget/index.jsx"));
// eslint-disable-next-line
const ChromeExtensionAuthentication = lazy(() =>
  import("@/pages/ChromeExtensionAuthentication/index.jsx"),
);
// eslint-disable-next-line
const TodoSchedulePage = lazy(() => import("@/pages/TodoSchedulePage/index.jsx"));

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/overview",
        element: <Overview />,
      },
      {
        path: "/timeline",
        element: <FiniteTimeline />,
      },
      {
        path: "/profile",
        element: <ProfileSetting />,
        children: [
          {
            path: "calendar",
            element: <CalendarSettings />,
          },
          {
            path: "category",
            element: <CategorySettings />,
          },
          {
            path: "devices",
            element: <Devices />,
          },
          {
            path: "reminders",
            element: <ReminderModal />,
          },
          {
            path: "weekly-hours",
            element: <WeekPreferenceModal />,
          },
          {
            path: "zones",
            element: <Zones isOnlyModal={false} />,
          },
          {
            path: "connected-apps",
            element: <ConnectedApps />,
          },
        ],
      },
      {
        path: "/todo",
        element: <TodoList />,
      },
      {
        path: "/schedule-link",
        element: <ScheduleLinks />,
      },
      {
        path: "time-budget",
        element: <TimeBudget />,
      },
    ],
  },
  {
    path: "/schedule/:id",
    element: <GuestSlots />,
  },
  {
    path: "/book/:id",
    element: <GuestSlots />,
  },
  {
    path: "/extension-authenticate",
    element: <ChromeExtensionAuthentication />,
  },

  {
    path: "/todo-schedule/:id",
    element: <TodoSchedulePage />,
  },
]);

export default appRouter;
